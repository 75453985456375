import React, { useEffect } from "react";
import { graphql } from "gatsby";
import "bootstrap/dist/css/bootstrap-grid.min.css";

import Layout from "../components/secondlayout";
import Accordion from "react-bootstrap/Accordion";
import { Row, Card, Button } from "react-bootstrap";
import { RichText } from "prismic-reactjs";

function SupportTemplate({ data, pageContext: { lang, currentPath } }) {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const accordion = document.querySelector(hash);
      if (accordion) {
        accordion.click();
        accordion.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  return (
    <Layout
      wpLang={lang}
      currentPath={currentPath}
      featuredImagePath="above-min.png"
    >
      <Row className="single-page">
        <div className="entry-content">
          <h1>{data.prismicSupport.data.title.text}</h1>
          {data.prismicHomepage.data.frequently_asked_questions_content.map(
            (value, index) => {
              return (
                <Accordion defaultActiveKey="0">
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle
                        as={Button}
                        variant="link"
                        eventKey="1"
                        id={value.question_url.text}
                      >
                        {value.question_title.text}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>
                        <RichText render={value.question_aswer.raw} />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              );
            }
          )}
        </div>
      </Row>
    </Layout>
  );
}
export const query = graphql`
  query support($lang: String) {
    prismicSupport(lang: { eq: $lang }) {
      data {
        title {
          text
        }
      }
      _previewable
      url
      lang
      id
    }
    prismicHomepage(lang: { eq: $lang }) {
      id
      data {
        frequently_asked_questions_content {
          question_aswer {
            html
            raw
            text
          }
          question_title {
            html
            raw
            text
          }
          question_url {
            html
            raw
            text
          }
        }
      }
      alternate_languages {
        uid
        type
        lang
      }
      _previewable
      url
      lang
    }
  }
`;
export default SupportTemplate;
