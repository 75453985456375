/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";

import DarkHeader from "./darkheader";
import Footer from "./footer";
import "./layout.css";
import Seo from "./seo";

const SecondLayout = ({
  children,
  wpLang,
  currentPath,
  pageTitle,
  pageMeta,
  featuredImagePath,
}) => {
  return (
    <>
      <DarkHeader wpLang={wpLang} currentPath={currentPath} />
      <Seo
        pageTitle={pageTitle}
        pageMeta={pageMeta}
        pageLang={wpLang}
        featuredImagePath={featuredImagePath}
      />
      <div className="main-wrap dark-content">
        <main>{children}</main>
      </div>
      <Footer pageLang={wpLang} />
    </>
  );
};

SecondLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SecondLayout;
